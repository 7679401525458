import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@/layout"
import {
  Box,
  CTA,
  SEO,
  Width,
  Heading,
  Text,
  SmallWave,
  MentorAlert,
  ArrowAlt,
  CalComplete,
  CalIncomplete,
  PromoBanner,
  Relationships,
  Grow,
  MentorCard,
  CaseStudyCard,
  TestimonialCard,
} from "@/components/index"
import { color } from "@/theme/index"

export default function Mentorship({ data }) {
  // let nextMonday = new Date()
  // nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7))
  // const nextMondayDate =
  //   nextMonday.getMonth() +
  //   1 +
  //   "/" +
  //   nextMonday.getDate() +
  //   "/" +
  //   nextMonday.getFullYear()
  return (
    <Layout headerType="simple" headerTheme="light" footerTheme="light">
      <SEO
        title="Sonora’s Guitar Mentorship Programs"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <Box
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt="9.6rem"
      >
        {/* Accelerated Learning */}
        <Box variant="slopeBottomLeft">
          <Width pt="8rem" pb="0rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 6 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading mb="3.6rem">Join Over 2500 Sonora Alumni</Heading>
              <Text mt="3.6rem">
                From hobbyists looking to dive deeper into their passion, to
                rock stars selling out stadiums, our Alumni inspire us every day
                with the breadth of their creativity and growth on the
                instrument.
              </Text>
            </Box>
          </Width>
        </Box>

        {/* Mentor Section One */}
        <Box
          bg={color.opal_hover}
          variant="slopeTopAndBottom"
          mt="-3.6rem"
          pb="6rem"
        >
          <Heading textAlign="center" py="3.6rem">
            Testimonials
          </Heading>
          <Width>
            <Box
              display="grid"
              gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
              gridGap={["3.8rem", null, "6rem 2rem", null]}
              mx="auto"
              width={["100%", "80%", 10 / 12, null]}
            >
              {/* Individual Mentors */}
              <TestimonialCard
                name="Steve C."
                slug="david-kaufman"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/764223818/29cc36c430"
              >
                “The value I’ve got out of it has been the best money, forget
                about just guitar, on just my life and my well being in
                decades.”
              </TestimonialCard>
              <TestimonialCard
                name="Jim P."
                slug="jim-p"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/877686630/e07e92a75a"
              >
                “I was very quickly able to do things that I've always wanted to
                but have never been able to.”
              </TestimonialCard>
              <TestimonialCard
                name="Mark"
                slug="mark"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/877687309/052ba9ca60"
              >
                “Sonora exceeded my expectations... The balance of structure,
                learning, and doing created a fantastic experience for me.”
              </TestimonialCard>
              <TestimonialCard
                name="Bill C."
                slug="Bill"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/877686524/f8c5a2d00b"
              >
                “The growth has been ridiculously effective!”
              </TestimonialCard>
              <TestimonialCard
                name="Bob W"
                slug="bob-w"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/877687200/92cec5b32f"
              >
                “Sonora as a whole is fantastic, I recommend it to everybody and
                I talk to people about it all of the time.”
              </TestimonialCard>

              <TestimonialCard
                name="Jim H"
                slug="jim-h"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/877689930/a4a7cea4f7"
              >
                "The information, coupled with the daily feedback has been a
                really great formula for me.”
              </TestimonialCard>
              <TestimonialCard
                name="Scott G"
                slug="scott"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/877690971/422e3e81b2"
              >
                "I've never before seen as clear a path for my progress on the
                instrument and I highly recommend it to anyone considering the
                program.”
              </TestimonialCard>
              <TestimonialCard
                name="Alonzo E."
                videoUrl="https://vimeo.com/495898443/6840a02942"
              >
                “I’m starting to discover a sound… I’m a lot more comfortable
                and a lot more confident in my playing. I feel I am well on my
                way to becoming the guitarist that I am imagining!”
              </TestimonialCard>
              <TestimonialCard
                name="Francesca B."
                videoUrl="https://vimeo.com/495898172/fe3a021ab4"
              >
                “I can’t remember the last time I experienced waking up in the
                morning and really wanting to grab the guitar and start playing…
                it’s an awesome feeling, and GMI (Sonora) gave me that.”
              </TestimonialCard>
              <TestimonialCard
                name="David M."
                videoUrl="https://vimeo.com/495898725/00be0249c2"
              >
                “My playing has left the pentatonic box. It’s gone out of the
                little ‘safety playpen’ of 1-4-5… I appreciate this roadmap,
                this way of learning, and the community that’s built around it.”
              </TestimonialCard>
              <TestimonialCard
                name="Lila A."
                videoUrl="https://vimeo.com/495909821/66f5f56a8d"
              >
                “The people that make up the team are so incredibly supportive,
                knowledgeable, sincere, and genuine about wanting you to get
                better. It’s really an awesome program and I couldn’t be more
                grateful!”
              </TestimonialCard>
              <TestimonialCard
                name="Erik L."
                videoUrl="https://vimeo.com/495909648/583785dc45"
              >
                “It’s crazy how much better I’m getting! The biggest thing is
                how much FUN I’m having when I’m soloing now… It’s like you can
                see something that was invisible, it’s like opening another
                eye.”
              </TestimonialCard>
            </Box>
          </Width>
        </Box>

        {/* Modern Study */}
        <Box variant="slopeTopRight" mt="-3.6rem">
          <Width pt="3.6rem" pb="6rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 6 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading level="2" mb="2rem">
                Notable Alumni
              </Heading>
            </Box>
            <Width>
              <Box
                display="grid"
                gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
                gridGap={["3.8rem", null, "6rem 2rem", null]}
                mx="auto"
                width={["100%", "80%", 10 / 12, null]}
              >
                {/* Individual Mentors */}
                <CaseStudyCard
                  name="Jesse Carmichael"
                  subtitle="GUITARIST FOR MAROON 5"
                  slug=""
                  image={data.jCarmichaelImage.childImageSharp.fluid}
                >
                  Best known as the keyboardist and rhythm guitarist for the pop
                  rock band, <b>Maroon 5</b>. Jesse came to us at the
                  intermediate plateau and worked with us to find more creative
                  freedom and improvisational ability on the instrument.
                </CaseStudyCard>
                <CaseStudyCard
                  name="Billy Strings"
                  subtitle="GRAMMY WINNING BLUEGRASS GUITARIST"
                  slug=""
                  image={data.billyStringsImage.childImageSharp.fluid}
                >
                  Billy is an iconic bluegrass guitarist and came to us as a
                  student looking to dive deeper into fluency on the fretboard
                  and explore the jazz idiom. He has been studying with us for
                  over 6 months and his continued work in the program informed
                  some of the songs on his upcoming album.
                </CaseStudyCard>
                <CaseStudyCard
                  name="Jason White"
                  subtitle="GUITARIST FOR GREEN DAY"
                  slug=""
                  image={data.jasonWhiteImage.childImageSharp.fluid}
                >
                  Best known as the touring guitarist for the rock band,{" "}
                  <b>Green Day</b>. Jason worked through the core program to
                  improve his fretboard knowledge and grow as a band leader.
                </CaseStudyCard>

                <CaseStudyCard
                  name="Marlon Williams"
                  subtitle="GUITARIST FOR SNOOP DOG AND KENDRICK LAMAR"
                  slug=""
                  image={data.marlonWilliamsImage.childImageSharp.fluid}
                >
                  Marlon is a hip hop guitarist and music director best known
                  for his work with Snoop Dogg, Kendrick Lamar and Kamasi
                  Washington. He came to us in order to improve his skills as a
                  producer and guitar player.
                </CaseStudyCard>
              </Box>
            </Width>
            <Box width="100%" mx="auto" textAlign="center">
              <CTA to="/application" variation="primary" mt="3.6rem">
                Apply Now
              </CTA>
            </Box>
          </Width>
        </Box>

        {/* Mentor Section Two */}
        <Box
          bg={color.opal_hover}
          variant="slopeTopAndBottomAlt"
          mt="-3.6rem"
          mb="-3.6rem"
          pb="6rem"
        >
          <Width pt="1.8rem">
            <Box
              display="grid"
              gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
              gridGap={["3.8rem", null, "6rem 2rem", null]}
              mx="auto"
              width={["100%", "80%", 10 / 12, null]}
            >
              {/* Individual Mentors */}
              <TestimonialCard
                name="Jake T."
                slug="david-kaufman"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/773520377/ba7a74ed02"
              >
                "You guys have distilled down guitar playing into it's most
                fundamental and important aspects and laid them out in a way
                that is condusive to the most efficient learning possible. "
              </TestimonialCard>
              <TestimonialCard
                name="Cody T."
                slug="david-kaufman"
                videoUrl="https://vimeo.com/764222459/c2199ce04f"
              >
                “The program is beyond reproach, there is nothing out there that
                compares, this is by far the best.”
              </TestimonialCard>
              <TestimonialCard
                name="Erwin H."
                slug="david-kaufman"
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/764256204/23f3b97485"
              >
                "What Spencer, Ila and the team is fabulous, I'm playing with
                friends now and it feels like it's going to stick."
              </TestimonialCard>
              <TestimonialCard
                name="Judson C."
                slug="david-kaufman"
                videoUrl="https://vimeo.com/768240036/3e5b40b2f7"
              >
                "The fretboard just looks so much smaller now... it's like this
                puzzle that finally starting to click."
              </TestimonialCard>
              <TestimonialCard
                name="Clint S."
                slug="david-kaufman"
                videoUrl="https://vimeo.com/766562097/c2e4b8aaa7"
              >
                "Sonora puts everything together as a comprehensive whole.
                Through the mentorship, the curriculum and Q&As, everything
                comes together in a unified sense."
              </TestimonialCard>
              <TestimonialCard
                name="Pete S."
                slug="david-kaufman"
                videoUrl="https://vimeo.com/764260422/f6b0d1a314"
              ></TestimonialCard>
              <TestimonialCard
                name="David S."
                videoUrl="https://vimeo.com/764260127/812cbcaeb1"
              ></TestimonialCard>
              <TestimonialCard
                name="Jeffrey O."
                image={data.jordanPetersImage.childImageSharp.fluid}
                videoUrl="https://vimeo.com/764254391/985f972fba"
              >
                "What you guys are doing is just really amazing, and I hope you
                know that."
              </TestimonialCard>
              <TestimonialCard
                name="Steve K."
                videoUrl="https://vimeo.com/764260829/077f7bdc61"
              ></TestimonialCard>
              <TestimonialCard
                name="Howard L."
                videoUrl="https://vimeo.com/764256733/0b524da474"
              ></TestimonialCard>
              <TestimonialCard
                name="Bruce G."
                videoUrl="https://vimeo.com/764515578/7a31ae8328"
              ></TestimonialCard>
              <TestimonialCard
                name="David K."
                slug="david-kaufman"
                videoUrl="https://vimeo.com/764256685/79f99d9bca"
              ></TestimonialCard>
            </Box>
          </Width>
        </Box>

        {/* Promotional Banner */}
        <PromoBanner />
      </Box>
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    jCarmichaelImage: file(relativePath: { eq: "jCarmichael.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    billyStringsImage: file(relativePath: { eq: "billyStrings.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jasonWhiteImage: file(relativePath: { eq: "jasonWhite.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    marlonWilliamsImage: file(relativePath: { eq: "marlonWilliams.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ariannaPowellImage: file(relativePath: { eq: "ariannaPowell.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    beccaStevensImage: file(relativePath: { eq: "beccaStevens.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    brianGreenImage: file(relativePath: { eq: "brianGreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curtHendersonImage: file(relativePath: { eq: "curtHenderson.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    horaceBrayImage: file(relativePath: { eq: "horaceBray.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jeffParkerImage: file(relativePath: { eq: "jeffParker.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jordanPetersImage: file(relativePath: { eq: "jordanPeters.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    joshSklairImage: file(relativePath: { eq: "joshSklair.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markWhitfieldImage: file(relativePath: { eq: "markWhitfield.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mimiFoxImage: file(relativePath: { eq: "mimiFox.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mollyMillerImage: file(relativePath: { eq: "mollyMiller.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nicholasVeinoglouImage: file(
      relativePath: { eq: "nicholasVeinoglou.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    noraBiteImage: file(relativePath: { eq: "noraBite.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    robbCappellettoImage: file(relativePath: { eq: "robbCappelletto.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    rotemSivanImage: file(relativePath: { eq: "rotemSivan.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ryanLermanImage: file(relativePath: { eq: "ryanLerman.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    steveCardenasImage: file(relativePath: { eq: "steveCardenas.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    tjWhitelawImage: file(relativePath: { eq: "tjWhitelaw.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    alicynYaffeeImage: file(relativePath: { eq: "alicynYaffee.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
